<template>
    <VirtualsResults :gameName="'keno'"></VirtualsResults>
</template>

<script>
import VirtualsResults from './Components/VirtualsResults';
export default {
    name: 'keno',
    components: {
        VirtualsResults,
    },
};
</script>
